@media screen and (max-width: 600px), screen and (max-device-width: 480px) {
  html {
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }
}

@media screen and (max-width: 700px) {
  body {
    width: 90%;
    margin: 15px auto 80px auto;
  }
  div#header {
    margin-top: 2em;
  }

  /* Index page items */
  .item-title,
  .item-description {
    float: none;
    width: auto;
    margin-left: 0;
    display: block;
  }

  .item-title {
    margin-bottom: 0.2em;
  }

  /* Site header */
  .site-header {
    margin-bottom: 2em;
  }
}
