.site-header {
  padding-bottom: 1em;
  margin-bottom: 6em;
}

a.site-header-title {
  font-weight: 700;
  color: $default-text-color;
  padding-right: 7px;
}

.site-header-secondary-title {
  color: $default-text-color;
}

.site-header-menu {
  margin-top: 0.5em;
  font-weight: 400;
}

.site-header-menu .site-header-secondary-title {
  margin-right: 1em;
}

.site-header-menu a.site-header-secondary-title.selected {
  color: $default-text-color;
}

#intro p {
  margin-bottom: 2em;
}
