%font-stack {
  font-family: "Univers", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
}

strong,
b {
  font-weight: 700;
}

em {
  font-style: italic;
}
