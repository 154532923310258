// a project listing on the homepage or
// projects page

.indexItem {
  clear: left;
  margin-bottom: 1em;
  overflow: hidden;
}

.indexItem-title {
  display: inline-block;
  width: 20%;
  margin-right: 5%;
  float: left;
}

.indexItem-description {
  display: inline-block;
  float: left;
  width: 75%;
}

.indexItem-dash {
  display: none;
}
