// indexGroup
//
// a group of indexItems

.indexGroup {
  margin-bottom: 2em;
  clear: left;
}

.indexGroup:last-child { margin-bottom: 0; }

.indexGroup-title {
  margin-bottom: 1em;
  a { color: $default-text-color; }
}
