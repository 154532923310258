// a drawing on the drawings page

.drawingItem {
  margin-bottom: 3em;
}

.drawingItem-image {
  width: 100%;
  display: block;
}

.drawingItem-caption {
  margin-top: 1em;
  display: none;
}
