body {
  @extend %font-stack;
  // background-color: #f5f4f0;
  background-color: white;
  font-size: 1em;
  color: $default-text-color;
  max-width: 600px;
  margin: 3em auto 9em auto;
  line-height: 1.5em;
}

body#home {
  line-height: 1.3em;
}

// Links
a,
a:visited {
  color: #b64b28;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

// Paragraphs
p {
  margin-bottom: 1em;
}

// Images
img,
video {
  max-width: 100%;
}

h2 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-weight: 700;
}

.hide {
  display: none;
}
