.post-header {
  margin-bottom: 2em;
}

h1,
.post-title {
  font-size: 1.4em;
  font-weight: 700;
  a {
    color: $default-text-color;
  }
}

.post-content p,
.post-content li {
  line-height: 1.5em;
}

.post-subtitle {
  margin-top: 1em;
  font-weight: 700;
}

.post-content h2,
.post-content h3 {
  margin-top: 2em;
  margin-bottom: 1em;
}

.post p,
.post ul,
.post ol {
  margin: 1em 0;
}

.post ol,
.post ul {
  padding-left: 2.5em;
}
.post ol {
  list-style-type: decimal;
  li {
    margin-bottom: 0.5em;
  }
}
.post ul {
  list-style-type: disc;
  li {
    margin-bottom: 0.5em;
  }
}

.post img {
  border: 1px solid $default-text-color;
}

pre {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-size: 12px;
  line-height: 16px;
  padding-left: 2.5em;
}

hr {
  height: 1px;
  background-color: #ccc;
  border: 0 none;
  margin: 2em 0;
}

// Blockquote
blockquote,
q {
  border-left: 1px solid #ccc;
  padding-left: 10px;
  color: #555;
}

// image caption
// use like this:
// ![A Short Hike](/assets/notes/2022/sabbatical.png)
// _A Short Hike_
img + em {
  font-size: 80%;
  margin-bottom: 10px;
  // font-style: normal;
}
