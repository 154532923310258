.post-link {
  .post-header {
    margin-bottom: 1em;
    h1 {
      font-size: 1em;
    }
    img.externalLink {
      border: 0;
      padding-left: 10px;
      width: 11px;
    }
  }

  .post-permalink {
    color: #CCC;
    margin-left: 0.8em;
    &:hover { color: #333; }
  }

  // p { padding-left: 15px; }
  // blockquote {
  //   margin-left: 15px;
  // }
  blockquote p, q p {
    padding-left: initial;
  }
}
