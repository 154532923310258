.noteEntry-date {
  color: #777;
  margin-top: 0.5em;
  font-size: 90%;
}

.noteEntry-date a {
  color: #777;
}

.noteEntry-date .noteEntry-category {
  color: #b64b28;
}

#post.notesindex {
  div.post {
    padding-top: 4em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 4em;
    padding-bottom: 2em;
    margin-bottom: 6em;
    &:first-child {
      border-top: 0;
      margin-top: initial;
      padding-top: initial;
    }
  }

  div.post-post + div.post-post {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  div.post-post + div.post-link {
    padding-top: 0;
  }

  div.post-link {
    margin-bottom: 0;
    padding-bottom: 0;
    border-top: 0;
    border-bottom: 0;
    margin-top: 0;
    padding-top: 4em;
  }
}

.note-embedded {
  padding: 30px;
  background-color: white;
  margin: 30px 0;
  // box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 0 black;
  border-radius: 3px;
  border: 1px solid black;
}
